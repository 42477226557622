<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS DOCUMENTOS EN LA CARPETA: {{(this.$route.params.nombre).toUpperCase()}}
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i class="fas fa-file fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaDocumentos.length}}</span>
                            <br>
                            <span class="text-muted ">Documentos registrados </span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de la carpeta {{this.$route.params.nombre}}</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" :to="{name: 'Documentos comerciales master'}">
                                <i class="fas fa-arrow-left fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Volver
                            </b-button>
                        </b-col>
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="modalNuevoDocumento = true">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo documento
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Documentos</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaDocumentos" :fields="camposDocumentos" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(etiqueta)="param">

                                        <h5 v-if="param.item.etiqueta==1">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> Muy importante
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="param.item.etiqueta==2">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> Importante
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="param.item.etiqueta==3">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> Poco importante
                                            </b-badge>
                                        </h5>
                                        <h5 v-if="param.item.etiqueta==4">
                                            <b-badge variant="danger">
                                                <b-icon icon="tag"></b-icon> No importante
                                            </b-badge>
                                        </h5>

                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button @click="abrirModalModificarDocumento(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <b-button class=" mr-1 mb-1" variant="blue" size="sm" v-c-tooltip="'Descargar'" @click="descargarDocumento(param)">
                                            <i class="fas fa-download my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="eliminarDocumento(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>

                        </b-row>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalNuevoDocumento">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-plus-square fa-md"></i>
                                    <span class="h5"> Nuevo documento </span>
                                </h6>
                                <CButtonClose @click="modalNuevoDocumento = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(registrarDocumento)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosDocumento.nombre"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="etiqueta" rules="required" v-slot="{errors}">
                                                <b-form-group label="Etiquetas:" class="mb-2">
                                                    <v-select :reduce="comboEtiqueta => comboEtiqueta.idEtiqueta" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDocumento.etiqueta , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDocumento.etiqueta" :options="comboEtiqueta" @search:blur="blurEtiqueta">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <!-- {{datosDocumento.archivoMeta}} -->
                                        <b-col md="12">
                                            <validation-provider :rules="{required: true}" name="documento" v-slot="validationContext">
                                                <b-form-group label="Documento:" class="mb-2">
                                                    <b-form-file ref="file" v-model="datosDocumento.archivoMeta" :state="(getValidationState(validationContext) )" placeholder="Elija un archivo o arrástrelo aquí..." accept=".pdf,.docx, application/msword, application/vnd.ms-excel" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalNuevoDocumento = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>

                        <CModal :closeOnBackdrop="false" color="dark" :show.sync="modalModificarDocumento">
                            <template #header>
                                <h6 class="modal-title">
                                    <i class="fas fa-edit fa-md"></i>
                                    <span class="h5"> Actualizar documento </span>
                                </h6>
                                <CButtonClose @click="modalModificarDocumento = false" class="text-white" />
                            </template>

                            <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                <b-form @submit.stop.prevent="handleSubmit(modificarDocumento)">
                                    <b-row>
                                        <b-col md="12">
                                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Nombre:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosModificarDocumento.nombre"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="12">
                                            <validation-provider name="etiqueta" rules="required" v-slot="{errors}">
                                                <b-form-group label="Etiquetas:" class="mb-2">
                                                    <v-select :reduce="comboEtiqueta => comboEtiqueta.idEtiqueta " label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarDocumento.etiqueta , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarDocumento.etiqueta" :options="comboEtiqueta" @search:blur="blurEtiqueta">
                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <!-- <b-col md="12">
                                            <validation-provider name="documento" v-slot="validationContext">
                                                <b-form-group label="Documento:" class="mb-2">
                                                    <b-overlay :show="overlayDocumento" spinner-small rounded="sm" opacity="0.6">
                                                        <template #overlay>
                                                            <b-overlay :show="true" class="d-inline text-muted" spinner-small rounded="sm" opacity="0.6"></b-overlay>
                                                            <span class="px-3 text-muted"> Cargando ....</span>
                                                        </template>
                                                        <b-form-file v-model="datosModificarDocumento.archivoMeta" :state="(getValidationState(validationContext) && !overlayDocumento )" placeholder="Elija un archivo o arrástrelo aquí..." accept=".pdf, application/msword, application/vnd.ms-excel" browse-text="Seleccionar" ref="input-documento" @change="subirDocumento"></b-form-file>
                                                    </b-overlay>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    <span :class="alertaArchivo.color" v-if="datosModificarDocumento.archivoMeta != null && !overlayDocumento" style="width: 100%;margin-top: 0.25rem;font-size: 80%">{{alertaArchivo.mensaje}}</span>
                                                </b-form-group>
                                            </validation-provider>
                                            <span v-if="datosModificarDocumento.url!=''">{{datosModificarDocumento.nombreArchivo}}</span>
                                        </b-col> -->
                                        <b-col md="12">
                                            <!-- {{datosModificarDocumento.name}} -->
                                            <!-- {{datosModificarDocumento.archivoMeta}} -->
                                            <validation-provider :rules="{}" name="documento" v-slot="validationContext">
                                                <b-form-group label="Documento:" class="mb-2">
                                                    <b-form-file ref="filex" v-model="datosModificarDocumento.archivoMeta" :state="(getValidationState(validationContext) )" :placeholder="datosModificarDocumento.nombreArchivo == '' ?  'Elija un archivo o arrástrelo aquí...' : datosModificarDocumento.nombreArchivo" accept=".pdf,.docx, application/msword, application/vnd.ms-excel" browse-text="Subir" v-on:change="handleEditarFileUpload"></b-form-file>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>

                                            </validation-provider>
                                        </b-col>
                                        <b-col class="my-2 text-right" md="12">
                                            <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                                                Guardar
                                            </b-button>
                                            <b-button size="md" variant="danger" @click="modalModificarDocumento = false">
                                                Cancelar
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>

                            </validation-observer>
                            <template #footer>
                                <span></span>
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            modalNuevoDocumento: false,
            modalModificarDocumento: false,
            totalRows: 1,
            currentPage: 1,
            porPagina: 5,
            filter: null,
            filterOn: [],
            paginaOpciones: [5, 10, 20, {
                value: 100,
                text: "Mostrar todos"
            }],
            comboEtiqueta: [{
                    idEtiqueta: '1',
                    descripcion: 'Muy Importante',
                },
                {
                    idEtiqueta: '2',
                    descripcion: 'Importante',
                }, {
                    idEtiqueta: '3',
                    descripcion: 'Poco Importante',
                }, {
                    idEtiqueta: '4',
                    descripcion: 'No Importante',
                }
            ],
            camposDocumentos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "fechaModificacion",
                    label: "Última modificación",
                    class: "text-center",
                },
                {
                    key: "etiqueta",
                    label: "Etiqueta",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaDocumentos: [],
            datosDocumento: {
                idDocumento: '',
                idCarpeta: '',
                nombre: '',
                etiqueta: '',
                url: null,
                nombreArchivo: '',
                archivoMeta: null,
            },
            datosModificarDocumento: {
                idDocumento: '',
                idCarpeta: '',
                nombre: '',
                etiqueta: '',
                url: null,
                nombreArchivo: '',
                archivoMeta: null,
            },
            disabled: false
        }
    },
    methods: {
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                this.datosDocumento.archivoMeta = this.$refs.file.files[0];
            }
        },
        handleEditarFileUpload(e) {
            if (!e.target.files[0]) {
                this.datosModificarDocumento.nombreArchivo = ''
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["filex"].reset();
                    return;
                }
                //this.swat('error', 'documento editar');
                this.datosModificarDocumento.archivoMeta = this.$refs.filex.files[0];
            }
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarDocumentos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-documentos-comerciales", {
                        params: {
                            idCarpeta: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.totalRows = response.data.length;
                    me.listaDocumentos = response.data;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');
                    me.$router.push({
                        name: "Documentos comerciales master"
                    })
                });
        },
        blurEtiqueta() {
            this.computedForm.refs.etiqueta.validate();
        },
        registrarDocumento() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosDocumento.archivoMeta);
            formData.append("folder", 'documentos-comerciales');
            /*---Datos del objeto--*/
            formData.append("idDocumento", me.datosDocumento.idDocumento);
            formData.append("idCliente", "0");

            formData.append("idCarpeta", me.datosDocumento.idCarpeta);
            formData.append("nombre", me.datosDocumento.nombre);
            formData.append("url", me.datosDocumento.url);
            formData.append("nombreArchivo", me.datosDocumento.nombreArchivo);
            formData.append("etiqueta", me.datosDocumento.etiqueta);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-documento-comercial",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalNuevoDocumento = false;
                    me.listarDocumentos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        descargarDocumento(param) {
            window.open(param.item.url)
        },
        modificarDocumento() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosModificarDocumento.archivoMeta);
            formData.append("folder", 'documentos-comerciales');
            /*---Datos del objeto--*/

            formData.append("idCliente", "0");

            formData.append("idDocumento", me.datosModificarDocumento.idDocumento);
            formData.append("idCarpeta", me.datosModificarDocumento.idCarpeta);
            formData.append("nombre", me.datosModificarDocumento.nombre);
            formData.append("url", me.datosModificarDocumento.url);
            formData.append("nombreArchivo", me.datosModificarDocumento.nombreArchivo);
            formData.append("etiqueta", me.datosModificarDocumento.etiqueta);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-documento-comercial",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalModificarDocumento = false;
                    me.listarDocumentos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        abrirModalModificarDocumento(param) {
            let me = this;
            me.datosModificarDocumento.nombre = param.item.nombre;
            me.datosModificarDocumento.etiqueta = param.item.etiqueta;
            me.datosModificarDocumento.url = param.item.url;
            me.datosModificarDocumento.idDocumento = param.item.idDocumento;
            me.datosModificarDocumento.nombreArchivo = param.item.nombreArchivo;
            me.modalModificarDocumento = true;
        },
        resetModalNuevoDocumento() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosDocumento.nombre = '',
                this.datosDocumento.url = null,
                this.datosDocumento.nombreArchivo = null,
                this.datosDocumento.archivoMeta = null,
                this.datosDocumento.etiqueta = null,
                this.overlayDocumento = false
        },
        resetModalModificarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosModificarDocumento.nombre = '',
                this.datosModificarDocumento.url = null,
                this.datosModificarDocumento.archivoMeta = null,
                this.datosModificarDocumento.etiqueta = null,
                this.overlayDocumento = false
        },
        eliminarDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-documento-comercial", {
                                idDocumento: param.item.idDocumento
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarDocumentos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalNuevoDocumento: function (val) {
            if (val == false) {
                this.resetModalNuevoDocumento();
            }
        },
        modalModificarDocumento: function (val) {
            if (val == false) {
                this.resetModalModificarDocumento();
            }
        }
    },
    mounted() {
        this.datosDocumento.idCarpeta = this.datosModificarDocumento.idCarpeta = this.$route.params.id;
        this.listarDocumentos();
    }
}
</script>
